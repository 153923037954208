import { IEnvConfig } from '..'

const config: IEnvConfig = {
  name: 'PRODUCTION',
  CONNECTORS: {
    ROOT: {
      baseUrl: 'https://api.mint-rex.dogeden.app',
    },
    SSO: {
      baseUrl: 'http://api.moverse.biz',
    }
  },
}

export default config
